import React, { useContext, useEffect, useState } from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "@components/Layout";
import TransitionLinkFade from "@components/TransitionLinkCategory";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../styles/categories.css";
import { useLocation } from "@reach/router";


import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import client from "@components/appolloClient";
import { myContext } from "../context/provider";

const categoriesData = gql`
  query MyQuery($slug: [String]!) {
    categories(slug: $slug) {
      id
      title
      slug
      ... on productCategories_Category {
        id
        categoryImage {
          ... on categoryImage_categoryimage_BlockType {
            id
            webImage {
              url
            }
            mobileImage {
              url
            }
          }
        }
        pageContent
      }
    }
    products(relatedToCategories: { slug: $slug }) {
      ... on collaborations_Product {
        title
        productEditions
        defaultPrice
        defaultPriceAsCurrency
        slug
        uri
        productGallery {
          id
          ... on uploads_Asset {
            id
            url
          }
        }
        price {
          ... on price_price_BlockType {
            id
            audPrice
            usdPrice
            euroPrice
          }
        }
      }
    }
  }
`;


const Category = () => {
   const location = useLocation();
   const [currency, setCurrency] = useState("USD");
    // Define a state variable to store the window width
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );
   const currentURL = typeof window !== 'undefined' ? window.location.href : location.pathname;
   const pathSegments = currentURL.split('/');
   const slugIndex = pathSegments.indexOf('categories');
   const [slug, setSlug] = useState(slugIndex !== -1 ? pathSegments[slugIndex + 1] : '');
   const { currencys } = useContext(myContext);

  useEffect(() => {
    // You can set the slug state if you need it for other purposes
    setSlug(slugIndex !== -1 ? pathSegments[slugIndex + 1] : '');
  }, [location]);

  const { loading, error, data } = useQuery(categoriesData, {
    variables: { slug }, // Use the updated slug directly
    client, // Replace with your Apollo Client instance if you're using it
  });

  if (loading) {
    return <p>.</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  console.log("full data",data);


  // Load the selected currency from session storage when the component mounts on the client
  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const storedCurrency = "USD";
  //     if (storedCurrency) {
  //       setCurrency(storedCurrency);
  //     }
  //   }
  // }, []);

  // console.log("full currency",currency);
  // console.log("full currencys",currencys);

  // Function to handle currency change
  const handleCurrencyChange = () => {
    setCurrency("AUD");
    // sessionStorage.setItem("selectedCurrency", selectedCurrency);
  };
 

  // const { craftCategoryInterface: entry } = data;
  const craftCollaborationsProduct = data.products;
  const craftCategoryInterface = data.categories;
  console.log("those are the products", craftCollaborationsProduct);
  console.log("those are the category", craftCategoryInterface);

  const banner =
    craftCategoryInterface && craftCategoryInterface?.[0].categoryImage
      ? craftCategoryInterface?.[0].categoryImage
      : ""; 

  const productData = data?.products
    ? data?.products
    : [];

    console.log("banner",banner);
    console.log("productData",productData);
 

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000, // Transition speed (1 second)
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000, // Static display duration (4 seconds)
  };
  console.log("settings",settings);
 
  const imageStyle = [];

  console.log("imageStyle",imageStyle);

 



  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);


 
  

  return (
    <Layout showNav={true} selectedCurrency={currency} onChange={handleCurrencyChange} >
      <div>
        <Slider className="category-banner" {...settings}>
          {banner &&
            banner.length > 0 &&
            banner.map((categoryData, i) => {
             

              const imageUrl =
                windowWidth <= 768
                  ? categoryData.mobileImage[0].url || ""
                  : categoryData.webImage[0].url || "";

              return (
                <React.Fragment key={i}>
                  (
                  <img
                    className=""
                    alt="image"
                    style={imageStyle}
                    src={imageUrl}
                  />
                  )
                </React.Fragment>
              );
            })}
        </Slider>
      </div>

      {slug && (
        <div className="category-inner">
          <div className="container">
            <div className="main-content">
              <h1 className="main-page-title">
                {craftCategoryInterface[0]?.title}
              </h1>

              {craftCategoryInterface && (
                <div
                  className="main-page-content"
                  dangerouslySetInnerHTML={{
                    __html: craftCategoryInterface[0].pageContent
                      .replace(/<p>/g, "")
                      .replace(/<\/p>/g, ""),
                  }}
                ></div>
              )}
            </div>
         
            <div className="main-categoy-products">
              <div className="product-row">
                {productData?.map((product, index) => {
            
                  return (
                    <Link className="category-anchor" to={`/${product?.uri}`}>
                      <div className="product-item-category" key={index}>
                        <div className="product-item-post">
                          {product?.productGallery?.length > 0 && (
                            <img
                              src={
                                product?.productGallery[0].url
                              }
                              alt={product?.title}
                            />
                          )}
                          <div className="category-product-details">
                            <h2 className="mt-2 font-bold">
                              {product && product?.title ? product?.title : null}
                            </h2>
                            <h3>
                              {product && product?.subtitle
                                ? product?.subtitle
                                : null}
                            </h3>
                            <h3>
                              Edition of{" "}
                              <span className="stock-level">
                                {product && product?.productEditions
                                  ? product?.productEditions
                                  : null}
                              </span>{" "}
                            </h3>
                            <span>
                              {currencys}{" "}
                              {currencys === "USD" ? (
                                product?.price[0]?.usdPrice + ".00"
                              ) : currencys === "EUR" ? (
                                product?.price[0]?.euroPrice + ".00"
                              ) : currencys === "AUD" ? (
                                product?.price[0]?.audPrice + ".00"
                              ) : (
                                <></>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Category;
